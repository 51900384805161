import React from 'react';
import './tachyons.min.css';

var StoryButtons = function (props) {
  function next(e) {
    e.preventDefault();
    props.handleChange('+');
  }
  function back(e){
    e.preventDefault();
    props.handleChange('-');
  }
  return (
  	<div className="flex lightest-blue w-100 h-auto">
  	  <button href="#0" className="no-underline bw0 w-50 bg-transparent back flex justify-center pa2 bt" onClick={back} disabled={props.page === 0 ? true : false}>
  	    <span className="pl1 justify-center tracked-tight">BACK</span>
  	  </button>
  	  <button href="#0" className="no-underline bw0 w-50 bg-transparent flex next justify-center pa2 bl bt"  onClick={next} disabled={props.page < props.length-1 ? false : true}>
  	    <span className="pr1 tracked-tight">NEXT</span>
  	  </button>
  	</div>
  );
}

export default StoryButtons