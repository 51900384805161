import React, { useState, useEffect } from 'react';
import './App.css';
import './tachyons.min.css';
// Import map tools
import DeckMap from './Map.js'
import StoryButtons from './StoryButtons';
import Tweet from './Tweet.js'
import Legend from './Legend.js'
import axios from 'axios';
import * as easings from 'd3-ease'
import {Spring} from 'react-spring/renderprops'
import {LinearInterpolator} from 'deck.gl';
import * as d3 from 'd3-fetch'
import WebWorker from './WebWorker';
//test

import {useSpring} from 'react-spring'


const transitionInterpolator = new LinearInterpolator();

const POLL_URLS = {
  '16': 'MC16PollingLocations.json',
  '08': 'MC08PollingLocations.json',
  'Sim': 'pollsim.json'
}

const VOTER_URLS = {
  'Hex': "mchexmap.json",
  'Trips': "voterstest.json"
}

const COUNTY_URLS = {
  'EDMI': "MC-CTY-EDvsMI.json",
  'Reg': "MC-CTY-Reg08t142.json"
}

const CD_URL = "MC-CD-EDvsMI.json"

const VOTERTRIP_URL = "vf.json"

const INITIAL_VIEW_STATE = {
  longitude: -112.0740,
  latitude: 33.4484,
  zoom: 9,
  pitch: 60,
  bearing: 30
};

var layerz = [
  {gj1data: POLL_URLS['16'], gj2data: POLL_URLS['08'], id: 'polls', gj1color: [101, 50, 62], gj2color: [101, 50, 62], switchtype: 'opacity', pickervalues: ['16', '08'], gj1Title: "Polls", legend: true},
  {gj1data: POLL_URLS['16'], gj2data: POLL_URLS['08'], id: 'polls', gj1color: [101, 50, 62], gj2color: [101, 50, 62], switchtype: 'opacity', pickervalues: ['16', '08'], gj1Title: "Polls", legend: true},
  {gj1data: COUNTY_URLS['EDMI'], gj2data: COUNTY_URLS['EDMI'], id: 'mailin', gj1color: [101, 50, 62], gj2color:[255, 192, 203], switchtype: 'colheight', pickervalues: ['10', '12', '14'], mvs: {zoom: 8}, columnsPerSwitch: 2, gj1Title: "Mail In", gj2Title: "Election Day", legend: true},
  {gj1data: CD_URL, gj2data: CD_URL, id: 'primarycomp', gj1color: [101, 50, 62], gj2color:[255, 192, 203], switchtype: 'colheight', pickervalues: ['Mar. Primary', 'Aug. Primary'],columnsPerSwitch: 2, gj1Title: "Mail In", gj2Title: "Election Day", legend: true},
  {gj1data: COUNTY_URLS['Reg'], mvs: {zoom: 7.85}, gj2data: COUNTY_URLS['Reg'], gj3data: COUNTY_URLS['Reg'], id: 'reghist', gj1color: [0, 32, 81], gj2color: [71, 61, 27], gj3color: [101, 50, 62], switchtype: "colheight", columnVal: true, min: 0, max: 15, columnsPerSwitch: 3,  legend: true, gj1Title: "Democrat", gj2Title: "Independent", gj3Title: "Republican"},
  {gj1data: COUNTY_URLS['Reg'], gj2data: COUNTY_URLS['Reg'], gj3data: COUNTY_URLS['Reg'], id: 'reghist', gj1color: [0, 32, 81], gj2color: [71, 61, 27], gj3color: [101, 50, 62], switchtype: "colheight", columnVal: true, min: 0, max: 15, columnsPerSwitch: 3,  legend: true, gj1Title: "Democrat", gj2Title: "Independent", gj3Title: "Republican"},
  {gj1data: COUNTY_URLS['Reg'], gj2data: COUNTY_URLS['Reg'], gj3data: COUNTY_URLS['Reg'], id: 'reghist', gj1color: [0, 32, 81], gj2color: [71, 61, 27], gj3color: [101, 50, 62], switchtype: "colheight", columnVal: true, min: 0, max: 15, columnsPerSwitch: 3,  legend: true, gj1Title: "Democrat", gj2Title: "Independent", gj3Title: "Republican"},
  {id:'none'},
  {gj1data: VOTER_URLS['Hex'], mvs: {zoom: 9}, gj2data: POLL_URLS['16'], gj2color: [0, 32, 81], id: 'dtphex', gj1color: [0, 32, 81], switchtype: "hex", scaleMin: "0 voters", scaleMax: "100 voters", scaleTitle:"Voters in Area", gj2Title: "Polls", legend: true},
  {gj1data: VOTER_URLS['Hex'], gj2data: POLL_URLS['16'], gj2color: [0, 32, 81], id: 'dtphex', gj1color: [0, 32, 81], switchtype: "hex", scaleMin: "0 voters", scaleMax: "100 voters", scaleTitle:"Voters in Area", gj2Title: "Polls", legend: true},
  {gj1data: VOTER_URLS['Hex'], gj2data: POLL_URLS['16'], gj2color: [0, 32, 81], id: 'dtphex', gj1color: [0, 32, 81], switchtype: "hex", scaleMin: "0 voters", scaleMax: "100 voters", scaleTitle:"Voters in Area", gj2Title: "Polls", legend: true},
  {id:'none'},
  {gj1data: POLL_URLS['Sim'], mvs: {zoom: 10}, tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"},
  {gj1data: POLL_URLS['Sim'], tripsdata: VOTERTRIP_URL, id: 'sim', columnsPerSwitch: 1, min: -500, max: 68400, increment: 5, timer: 50, timeVal: true, legend: true, scaleMin: "0 min.", scaleMax: "120 min.", scaleTitle:"Poll Wait Times", switchtype: "sim"}
]
  var switchTimer;

var tripsWorker; // Web Worker
var tripsFired = false; // Makes sure trips load message is only sent once.
var tripsAr = [];
var App = function (props)  {
  const [page, setPage] = useState(0);
  const [points, setPoints] = useState([]);
  const [layers, setLayers] = useState(layerz)
  const [curPage, setCurPage] = useState({gj1data: [], gj2data: [], tripsdata:[]})
  const [pageDirection, setDirection] = useState('+')
  const [curLayer, setCurLayer] = useState(0)
  const [changingLayer, setChangingLayer] = useState(false)
  const [mapViewState, setMapViewState] = useState(INITIAL_VIEW_STATE)
  const [heightCont, setHeightCont] = useState(null)
  const [columnChangeDir, setColumnChangeDir] = useState(true)
  const [autoSwitch, setAutoSwitch] = useState(false)
  const [tripsLoadPoint, setTripsLoadPoint] = useState(0)

  useEffect(() => { 
   // GET TWEETS 
   axios.get(process.env.REACT_APP_GET_TWEETS) 
     .then(function (response) {
      setPoints(response.data);
   })
   // Create Trips load worker
   tripsWorker = new Worker('loadWorker.js');
   changeLayer()
  }, []);

  useEffect(()=>{
    if(typeof tripsWorker !== "undefined"){
      tripsWorker.addEventListener('message', updateTrips);
      if(curPage.id !== 'sim' && page !== 0) {
        tripsAr = [];
      }
    }
    return () => { tripsWorker.removeEventListener('message', updateTrips) }
  }, [curPage])

  useEffect(()=>{
    if(typeof curPage.tripsdata !== 'undefined'){
      if(Math.abs(curLayer-tripsLoadPoint) > 5000 | curLayer>tripsLoadPoint){
        let newData = tripsAr.filter(el=>{
          return el.a<=curLayer+5000 & el.a>=curLayer;
        })
        let c = curPage;
        c.tripsdata = newData
        setCurPage(c)
        if(tripsAr.length<10000){
          setTripsLoadPoint(curLayer+1000)
        } else {
          setTripsLoadPoint(curLayer+5000)
        }
      }
    }
  }, [curLayer])

  useEffect(()=>{ // Switch Timer
    if(autoSwitch&!changingLayer){
      let cl;
      if(typeof curPage.pickervalues !== "undefined"){
        cl = curLayer<curPage.pickervalues.length-1 ? curLayer+1 : 0 
      } else {
        if(typeof curPage.increment !== "undefined"){
          let pot = curLayer+curPage.increment
          cl = pot < curPage.max ? pot : 0 
        } else {
          cl = curLayer<curPage.max ? curLayer+1 : 0 
        }
      }
      let time = typeof curPage.timer !== "undefined" ? curPage.timer : 2000
      switchTimer = window.setTimeout(() => {
        setColumnChangeDir(columnChangeDir?false:true)
        setCurLayer(cl)
      }, time)
    } else {
      window.clearTimeout(switchTimer)
    }
  }, [autoSwitch, curLayer])

  const updateTrips = (e) => {
    let l = curPage;
    if(typeof l.tripsdata !== 'undefined'){
      tripsAr = tripsAr.concat(e.data)
      console.log(tripsAr.length)
      if(l.tripsdata.length<5000){
        l.tripsdata = l.tripsdata.concat(e.data)
      }
    }
    setCurPage(l)
  }

  const setTest = (mvs) =>{
    setMapViewState(mvs.viewState)
  }

  const rotateCamera = (el, p) => {
    let transObj={}
    if(typeof p !== "undefined") {
      transObj= p.mvs
    }
    setMapViewState({
        ...mapViewState,
        ...transObj,
        bearing: (el.endProps.bearing + 40),
        transitionDuration: 10000,
        transitionInterpolator,
        onTransitionEnd: x=> rotateCamera(x, p),
        onTransitionInterrupt: ()=>console.log("Transition Interrupt")
      });
  }
  
  const pickLayer = (selection) => { // You need to have a flag that prevents the timer from re-running when everythings redrawn
    setAutoSwitch(false)
    setColumnChangeDir(columnChangeDir?false:true)
    setCurLayer(selection)
  }

  const changePage = (op) => { // CHANGE TWEET AND HANDLE CHANGE LAYER IF NEEDED
    if(changingLayer){
      window.clearTimeout(switchTimer)
      changeLayer();

    } else {
      if(op === '+' | op === '-'){
        setColumnChangeDir(true)
        let changedPage = page+('+'===op ? 1 : -1)
        if(layers[changedPage].id !== layers[page].id){
          setAutoSwitch(false)
          setCurLayer(0)
          setChangingLayer(true)
        }
        setPage(('+'===op ? page+1 : page-1)); 
        setDirection(op); 
      }
    }
  } 

  const changeLayer = (arg) => { // CHANGE LAYERS, A CHANGE PAGE DOES NOT ALWAYS EQUAL CHANGE IN LAYERS
    let requestIds = [];
    let dataPath = '/data/'
    let l = layers[page]
    rotateCamera({endProps: {bearing: mapViewState.bearing}}, l)

    Promise.all(Object.keys(l).filter(el=>el.indexOf('data')!==-1&el.indexOf('trips')==-1).map(k=>{
      let url = (dataPath+JSON.stringify(l[k]).slice(1,-1))
      if(k!=='id'){
        requestIds.push(k)
        if(l[k].slice(-3, l[k].length) === 'csv'){
          return d3.csv(url)
        } else {
          return d3.json(url)
        } 
      }
    })).then(function(values) {

      let pageObj = {};
      requestIds.forEach(i=>{
        pageObj[i] = values[requestIds.indexOf(i)]
      })
      Object.keys(l).filter(el=>el.indexOf('data')===-1).forEach(ke=>{ pageObj[ke] = l[ke] })
      if(typeof l.tripsdata !== 'undefined'){
        pageObj['tripsdata'] = []
      }
      setCurPage(pageObj)
      setChangingLayer(false)
      setAutoSwitch(true)
      if(typeof l.tripsdata !== 'undefined'){
        tripsWorker.postMessage(l.tripsdata)
      }
    });
  }
  const layerLoad = (arg) => {
    console.log(arg)
  }

  const handleSwitch = (property, id) =>{
    if(property === curPage['switchtype']){
      switch(curPage['switchtype']){
        case 'height':
          let data = curPage.gj1data.features[0]
          if(typeof data !== "undefined"){
            let keys = Object.keys(data.properties)
            let selectedKey = (id)+1+curLayer*2
            return data.properties[keys[selectedKey]]/10;
          } else { return 1; }
        break;
        case 'colheight':
        return columnChangeDir?1:0
        break;
        default:
        if(changingLayer){
          return 1;
        }
        return curLayer==id ? 1 : 0;
        break;
      }
    } else {
      switch(property){
        case 'height':
          if(curPage['switchtype'] === 'colheight' | curPage['switchtype'] === 'hex'){
            if(curPage['switchtype'] == 'hex'){
              return id==0 ? 20 : 10000
            } else { 
              if(curPage.id=='primarycomp'){
                return 2;
              } else {
                return .2;
              }
            }
          } else {
            return 10000;
          }
        break;
        default:
          return 1;
        break;
      }
    }
  }

  const hideLoading = () => {
    rotateCamera({endProps: {bearing: mapViewState.bearing}})
    console.log("Start trigger, fire timer after 5 seconds for onload.")
  }
  return ( // POTENTIALLY ADD TITLE
    <div>
      {curPage.legend && <Legend page={curPage} curLayer={curLayer}></Legend>}
      <div className="storypanel">
        <div className="content">
          <Tweet tweet={points.length !== 0 ? {...points[page], ...layers[page]} : {full_text: '', id: 'test', favorite_count: 0, retweet_count: 0}} direction={pageDirection}
          pickLayer={pickLayer} changePlaying={setAutoSwitch} curLayer={curLayer} curPage={curPage} playing={autoSwitch}>
          </Tweet>
          </div>
        <StoryButtons handleChange={changePage} page={page} length={points.length}></StoryButtons>
      </div>
      <div style={{height: 100+'vh',width:100+'vw',position:'absolute',overflow:'hidden'}}>
      <Spring to={ { op1: handleSwitch('opacity',0), op2: handleSwitch('opacity',1), gjescale: changingLayer ? 0 : handleSwitch('height',0), heightSel: handleSwitch('colheight', 1)} } onRest={changePage} config={ { duration: 500, easing: easings.easeCubic } }>{ propz => 
              <DeckMap layers={curPage} gj1op={propz.op1} heightSel={propz.heightSel} columnChangeDir={columnChangeDir} gj2op={propz.op2} gjescale={propz.gjescale} viewState={mapViewState} viewStateChange={setTest} curLayer={curLayer} onLoadz={hideLoading}>
              </DeckMap> 
        }</Spring>
      </div>
    </div>
  );
}

export default App;