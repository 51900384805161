import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faRetweet } from '@fortawesome/free-solid-svg-icons'
import {useTransition, animated} from 'react-spring'
import LayerPicker from './LayerPicker.js';
import LayerSlider from './LayerSlider.js';

var Tweet = function (props) {
  const transitions = useTransition(props.tweet, item=>item.id_str, {
  from: { opacity: 0, transform: props.direction==='+' ? 'translate3d(100%,0,0)' : 'translate3d(-50%,0,0)' },
  enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
  leave: { opacity: 0, transform: props.direction==='+' ? 'translate3d(-50%,0,0)' : 'translate3d(100%,0,0)' },
  })
  return transitions.map(({ item, key, props: propz }) => {
    return (
      <animated.div key={key} style={propz} className="tweet">
        {typeof item.pickervalues !== 'undefined' && <LayerPicker pickervalues={item.pickervalues} pickLayer={props.pickLayer} curLayer={props.curLayer} changePlaying={props.changePlaying} playing={props.playing}> </LayerPicker>}
        {typeof item.pickervalues == 'undefined' && <LayerSlider curPage={props.curPage} pickLayer={props.pickLayer} curLayer={props.curLayer} changePlaying={props.changePlaying} playing={props.playing} changePlaying={props.changePlaying} > </LayerSlider>}
        <animated.h3 > {item.full_text}  </animated.h3> 
        <div className="tweetStats"> 
          <FontAwesomeIcon icon={faHeart} transform={{rotate:0.03 }} className="faCustom"/> {item.favorite_count} 
          <FontAwesomeIcon icon={faRetweet} transform={{rotate:0.03 }} className="faCustom"/> {item.retweet_count}
        </div> 
      </animated.div> 
      )
    }
  );
}

export default Tweet
/*

curl -H "authorization: Bearer AAAAAAAAAAAAAAAAAAAAAGYw9wAAAAAAsmoTgEC8o11sGn2sMvImjL3DN1M%3Db7HWDpcn8zXL9U2638NJ3oakjbZQfopSiJlTVP2sGToOMzrQmx" 'https://api.twitter.com/1.1/statuses/user_timeline.json?since_id=1114753937163280386&count=10&screen_name=andrewpulcipher'

curl -u "58J3VpOhvCX8K8JKHFLbpC6nh:jAbxjLogOwwAMDQJWJS4XSLExvsJIrcyhP0B2e2inb0uyip0sB" \
   --compressed \
   --data ‘grant_type=client_credentials’ \
   ’https://api.twitter.com/oauth2/token'
   */