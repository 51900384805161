import './App.css';
import {animated, useSpring} from 'react-spring'
import React, { useState, useEffect } from 'react';
import * as interp from 'd3-interpolate'

var colorscale = interp.interpolateLab("#473d1b", "#471b2f")

function rgb(values) {
    return 'rgb(' + values.join(', ') + ')';
}

var Legend = function (props) {
	const scale = Array(50).fill().map((v, i)=>{
		let val = i/50
		return (
			<div style={{backgroundColor: colorscale(val)}} className="scaleval" key={i}></div> 
			)
	})
	const items = Object.keys(props.page).filter(k=>k.indexOf("Title")!==-1).map(k=>{
		let colorKey = k.slice(0, k.length-5)
		if(colorKey == "scale"){
			return (
				<div className="scale" key={k}>
					<b className="scaleTitle"> {props.page[k]}</b>
					<div className="scalevals"> {scale} </div>
					<div className="scaleMin"> {props.page.scaleMin} </div>
					<div className="scaleMax"> {props.page.scaleMax} </div>
				</div> 
			)
		} else {
			return (
				<div className="item" key={k}> 
					<b className="itemTitle"> {props.page[k]} </b>
					<div className="bd-color" style={{backgroundColor: rgb(props.page[(colorKey+'color')])}}> </div>
				</div>
			)
		}
	})
	const calcWalkoffs = () => {
		if(props.page.id=="sim"){
			let p = props.page.gj1data;
			let walkoffs = 0
			p.features.forEach(f=>{
				walkoffs = walkoffs + Math.floor(f.properties['walkoffs']*(props.curLayer / 68400))
			})
			return walkoffs + " Voters";
		}
	}
	const calcWait = () => {
		if(props.page.id=="sim"){
			let p = props.page.gj1data;
			let totalWait = 0
			p.features.forEach(f=>{
				let line;
				if(props.curLayer>46800){
				  line = f.properties['checkin'][51] - (props.curLayer-46800)/60
				  line = line < 0 ? 0 : line
				  totalWait = totalWait + Math.floor(line)
				} else {
					let t = Math.floor((props.curLayer / 46800)*51);
					totalWait = totalWait + f.properties['checkin'][t]
				}
			})
			return Math.floor(totalWait/60)+" Minutes";
		}

	}
	const simStats =  (
		<div>
		<div className="item"> 
			<b className="itemTitle"> Total Walkoffs </b>
			<div className="itemValue"> {calcWalkoffs()} </div>
		</div>
		<div className="item"> 
			<b className="itemTitle"> Average Wait Time </b>
			<div className="itemValue"> {calcWait()} </div>
		</div>
		<div className="item"> 
			<b className="itemTitle"> Polls </b>
			<div className="itemValue"> {props.curLayer>46800 ? "Closed" : "Open"}</div>
		</div>
		</div>
		)
	return (
		<div className="legend">
			{items}
			{props.page.id=="sim" && simStats}
		</div>
	)
}

export default Legend