import './App.css';
import {animated, useSpring} from 'react-spring'
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

var LayerPicker = function (props) {
	const [selX, setSelX] = useState(0); // X coordinate of selected background.
	const [selWid, setSelWid] = useState(0); // X coordinate of selected background.
	const [selHei, setSelHei] = useState(0); // X coordinate of selected background.
	const changePlaying = () => { props.changePlaying(!props.playing) }
	const selStyle = useSpring({"marginLeft": selX, width: selWid,height: selHei})
	const playPause = props.playing ? <FontAwesomeIcon icon={faPause}  transform={{rotate:0.03 }} className="faCustom" /> : <FontAwesomeIcon icon={faPlay} transform={{rotate:0.03 }} className="faCustom" />

	useEffect(() => {
		let children = document.querySelectorAll("#pickers > button")
		if(children.length>0 & props.curLayer < children.length){ 
			let tar = children[props.curLayer]
			let margin = document.querySelector("#pickerLeft").offsetLeft
			let sel = document.querySelector("#pickerSelector")
			setSelWid(tar.offsetWidth)
			setSelX((tar.offsetLeft-margin))
			if(!tar.classList.contains('pickerRight') | !tar.classList.contains('pickerLeft')){
				sel.classList.remove("left");
				sel.classList.remove("right");
			}
			if(tar.classList.contains('pickerRight')){ 
				sel.classList.remove("left");
				sel.classList.add("right");
			}
			if(tar.classList.contains('pickerLeft')){ 
				sel.classList.remove("right");
				sel.classList.add("left");
			}
		} 
	}, [props.curLayer]);
	useEffect(() => { // GET TWEETS
		let pick = document.querySelectorAll("#pickerLeft");
		let index;
		if(props.pickervalues[0] == pick[0].innerHTML | pick.length==1){
			index = 0;
		} else { index=1;}
		setSelWid(pick[index].offsetWidth);
		setSelHei(pick[index].clientHeight+2);
	}, [props.pickervalues]);
	const handleClick = (l, e) => {
		props.pickLayer(l)
	}

	const pickVals = props.pickervalues
	const middleOptions = pickVals.slice(1,(pickVals.length-1)).map( (val)=>
		<button className="pickerMiddle" key={val} onClick={ (e)=>{handleClick((pickVals.indexOf(val)), e)} }> 
			{val}
		</button>
	)
	return (
		<div className="pickers" id="pickers">
			<animated.div className="selected left" id="pickerSelector" style={selStyle}> </animated.div>
			<button className="pickerLeft" id="pickerLeft" onClick={ (e)=>{handleClick(0,e)} }> {pickVals[0]}</button>
			{middleOptions}
			<button className="pickerRight" onClick={ (e)=>{handleClick(pickVals.length-1, e)} }> {pickVals[pickVals.length-1]} </button>
			<div className="pickerPlay" onClick={changePlaying}>
				{playPause}
			</div>
		</div>
	)
}

export default LayerPicker