import './App.css';
import {animated, useSpring} from 'react-spring'
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

var LayerSlider = function (props) {
	const calcTime = (t) => {
		let pollOpen = 21600; // 6 AM in seconds.
		let curHour = Math.floor((pollOpen + t) / 60 / 60);
		curHour = curHour == 25 ? 1 : curHour 
		let curMinute = Math.floor((((pollOpen + t) / 60 / 60) - Math.floor((pollOpen + t) / 60 / 60))*60)
		curMinute = curMinute<10 ? "0"+curMinute.toString() : curMinute.toString()
		let ampm = curHour < 12 ? "AM" : "PM"
		curHour = curHour > 12 ? curHour-12 : curHour
		return curHour < 12 ? curHour.toString()+":"+curMinute+ampm : curHour.toString()+":"+curMinute+ampm;
		//if(curTime>12)
	}
	const pickVal = (e) => { props.pickLayer(Number(e.target.value)); }
	const changePlaying = () => { props.changePlaying(!props.playing) }
	const playPause = props.playing ? <FontAwesomeIcon icon={faPause} transform={{rotate:0.03 }} className="faCustom" /> : <FontAwesomeIcon icon={faPlay} transform={{rotate:0.03 }} className="faCustom"/>
	const sliderValue = props.curPage.columnVal ? <p className="sliderValue"> {Object.keys(props.curPage.gj1data.features[0].properties)[(props.curLayer+1)*props.curPage.columnsPerSwitch]} </p> : props.curPage.timeVal ? <p className="sliderValue"> {calcTime(props.curLayer)} </p> : <p className="sliderValue"> {props.curLayer} </p>
	

	return (
		<div className="sliders">
			<input type="range" value={props.curLayer} id="start" name="volume" min="0" max={props.curPage.max} onChange={pickVal} />
			<button className="playPause" onClick={changePlaying}> 
			{playPause}
			</button>
			{sliderValue}
		</div>
	)
}

export default LayerSlider