import {AmbientLight, PointLight, LightingEffect} from '@deck.gl/core';
import DeckGL, {HexagonLayer, GeoJsonLayer, TripsLayer} from 'deck.gl'
import {StaticMap} from 'react-map-gl';
import React, { Component } from 'react';
import {Controller} from 'react-spring/renderprops'
import * as easings from 'd3-ease';
import {PhongMaterial} from '@luma.gl/core';
import {quantileRank} from 'simple-statistics'
import * as interp from 'd3-interpolate'
import './App.css';
const material = new PhongMaterial({
  ambient: 1.0,
  diffuse: 0.5,
  shininess: 25,
  specularColor: [51, 51, 51]
});

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const pointLight = new PointLight({
  color: [255, 255, 255],
  intensity: 1.0,
  position: [-111.360758, 32.987458, 80000]
});

const PARTY_COLORS = {
  '1': [0, 32, 81],
  '0': [101, 50, 62],
  '2': [71, 61, 27]
}


const lightingEffect = new LightingEffect({ambientLight, pointLight});

const colorRange = [
  [1, 152, 189],
  [73, 227, 206],
  [216, 254, 181],
  [254, 237, 177],
  [254, 173, 84],
  [209, 55, 78]
];

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXB1bGNpcGhlciIsImEiOiJjams1MDN6MnEwejdjM3J0aHl5aHl0YjBwIn0.W34T-s8ivR-wkDV5MlELWQ';

const INITIAL_VIEW_STATE = {
  longitude: -112.0740,
  latitude: 33.4484,
  zoom: 9,
  pitch: 60,
  bearing: 30
};

const elevationScale = {min: 1, max: 10};

export class DeckMap extends Component {
  static get defaultColorRange() {
    return colorRange;
  }
  heightanim = new Controller({to: {height1: this.props.layers.switchtype==='colheight' ? 1 : 0, height2: this.props.layers.switchtype==='colheight' ?  1 : 0}}, { duration: 500, native: false, easing: easings.easeCubic, interpolateTo: true, autoStart: true })

  constructor(props) {
    super(props);
    this.state = {
      elevationScale: elevationScale.min,
      viewState: INITIAL_VIEW_STATE,
      hoveredObject: null,
    };
    this.curCol = 0
    this.prevCol = -1
    this.curChangeDir = true;
    this.pageId = '0'
    this._onHover = this._onHover.bind(this);
    this._renderTooltip = this._renderTooltip.bind(this);
  }
   _onHover({x, y, object, layer}) {
    let combObj = {...object, id: Number(layer['id'].slice(2,layer['id'].length))}
    this.setState({x, y, hoveredObject: combObj});
  }
  _renderTooltip() {
    const {x, y, hoveredObject} = this.state;
    var attr = ""
    let returnObj = {}
    if(hoveredObject&&hoveredObject.properties){
      switch(this.props.layers.switchtype){
        case 'colheight':
          let curCol = 1+(hoveredObject.id-1) + (this.props.curLayer*this.props.layers.columnsPerSwitch);
          let keys = Object.keys(hoveredObject.properties)
          returnObj[keys[curCol]] = hoveredObject.properties[keys[curCol]] + " Voters"
          if(this.props.layers.id=='primarycomp'){
            returnObj['CD'] = hoveredObject.properties['CD']
          }
          attr =  Object.keys(returnObj).map((att) =>
            <li key={att}> <b> {att}: </b> {returnObj[att]}</li>
          );
        break;
        case 'sim':
          let staticKeys = ['Facility Name', 'Facility Address', 'City']
          staticKeys.forEach(k=>returnObj[k] = hoveredObject.properties[k])
          returnObj['Total Voters'] = hoveredObject.properties['voters2']
          returnObj['Actual Close Time'] = hoveredObject.properties['closed']
          returnObj['Current Walkoffs'] = Math.floor(hoveredObject.properties['walkoffs']*(this.props.curLayer / 68400))
          if(this.props.curLayer>46800){
            let line = hoveredObject.properties['checkin'][51] - (this.props.curLayer-46800)/60
            line = line < 0 ? 0 : line
            returnObj['Current Wait'] = Math.floor(line) + " Minutes";
          } else {
            let p = Math.floor((this.props.curLayer / 46800)*51); // percent of day
            returnObj['Current Wait'] = hoveredObject.properties['checkin'][p] + " Minutes"
          }
         attr =  Object.keys(returnObj).map((att) =>
            <li key={att}> <b> {att}: </b> {returnObj[att]}</li>
          );
        break;
        default:
        attr = Object.keys(hoveredObject.properties).map((att) =>
          <li key={att}> <b> {att}: </b> {hoveredObject.properties[att]}</li>
        );
        break;
      }
    }
    return (
      hoveredObject &&hoveredObject.properties&& (
        <div className="tooltip" style={{top: y, left: x}}>
         {attr}
        </div>
      )
    );
  }
    calculateGjElevation = (x, a) => {
      if(this.props.layers.switchtype!=='colheight'){
        if(this.props.layers.switchtype == 'hex' & a==0){
          //console.log(x.properties.voters)
          return x.properties.voters;
        }else {
          if(this.props.layers.switchtype == 'hex' & a==1){
            return 500;
          } else {
            return .5;
          }
        }
      } else { // Height selector.
          let keys = Object.keys(x.properties)
          if(this.curChangeDir!=this.props.columnChangeDir){
            this.curChangeDir=this.props.columnChangeDir
            this.prevCol= this.curCol
            this.curCol= this.props.curLayer
          }
          if(this.pageId != this.props.layers.id){
            this.prevCol=-1
            this.curCol = 0            
            this.pageId = this.props.layers.id
          } 
          let prevCol = this.prevCol;
          let curCol = this.curCol;
          let curKey = 1 + a + (curCol*this.props.layers.columnsPerSwitch)
          let prevKey = 1 + a + (prevCol*this.props.layers.columnsPerSwitch)

          if(a!=this.props.layers.columnsPerSwitch-1){
            let heightBelow=0;
            let belCol=0;
            for(var k = 1+a; k<this.props.layers.columnsPerSwitch; k++){
              // 1 needs 2 and 3
              // 2 Needs 3
              let belKey = 1 + k + (curCol*this.props.layers.columnsPerSwitch)
              belCol = x.properties[keys[belKey]]
              heightBelow = heightBelow+belCol
            }
            curCol = x.properties[keys[curKey]]
            curCol = heightBelow + curCol
            if(prevCol!=-1){
              heightBelow = 0;
              for(var k = 1+a; k<this.props.layers.columnsPerSwitch; k++){
                // 1 needs 2 and 3
                // 2 Needs 3
                let belKey = 1 + k + (prevCol*this.props.layers.columnsPerSwitch)
                belCol = x.properties[keys[belKey]]
                heightBelow = heightBelow+belCol
              }
              prevCol = x.properties[keys[prevKey]]
              prevCol = heightBelow + prevCol

            }

          } else {
            prevCol = prevCol!=-1 ? x.properties[keys[prevKey]] : curCol 
            curCol = x.properties[keys[curKey]]
          }
          let heightMult = this.props.columnChangeDir ? this.props.heightSel : (1-this.props.heightSel)
          return (prevCol + (curCol-prevCol)*heightMult)/5
      }
    }
    calculateColor = (x, a) => {
      var colorscale = interp.interpolateLab("#473d1b", "#471b2f")
      if(this.props.layers.switchtype == 'hex' & a == 1){
        let rank = quantileRank(this.props.layers.gj1data.features.map(x=>x.properties.voters), x.properties.voters)
        let color = colorscale(rank)
        return this.getRGB(color)
      } else {
        if(this.props.layers.id == 'sim'){
          let startTime = 21600 // 6 AM 
          let cl = this.props.curLayer;
          cl = Math.floor((cl) / 60 / 15);
          if(cl<52){
            return this.getRGB(colorscale(x.properties.checkin[cl]/120))
          } else {
            let tPastClose = cl-51
            let waitTime = x.properties.checkin[51] - tPastClose*15
            waitTime = waitTime>0 ? waitTime: 0
            return this.getRGB(colorscale(waitTime/120))
          }
        } else {
          return this.props.layers[('gj'+a+'color')]
        }
      }
    }
     getRGB = (str) => {
      var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
      return match ? [Number(match[1]), Number(match[2]), Number(match[3])] : [];
    }

  _renderLayers() {
    return [new GeoJsonLayer({
        id: 'gj1',
        data: this.props.layers.gj1data,
        pointRadiusMinPixels: 4,
        pointRadiusMaxPixels: 7,
        getFillColor:  x=>this.calculateColor(x, 1),
        opacity: this.props.gj1op,
        filled: true,
        extruded: true,
        autoHighlight: true,
        pickable: this.props.gj1op !== 0,
        updateTriggers: {
                getElevation: [this.props.heightSel],
                getFillColor: [this.props.layers.gj1color, this.props.layers.gj2color, this.props.layers.gj3color, this.props.curLayer]
            },
        material,
        onHover: this._onHover,
        getElevation: x=>this.calculateGjElevation(x,0),
        visible: this.props.layers.gj1data !== 'na',
        elevationScale: this.props.gjescale,
        parameters: this.props.layers.switchtype ==='opacity'&this.props.layers.switchtype !=='hex' ? { depthMask: false } : {}
      }),
      new GeoJsonLayer({
        id: 'gj2',
        data: this.props.layers.gj2data,
        getFillColor: x=>this.calculateColor(x, 2),
        stroked: true,
        pickable: this.props.gj2op !== 0,
        material,
        onHover: this._onHover,
        updateTriggers: {
                getElevation: [this.props.heightSel]
              //  pickable: this.props.
            },
      //  dataComparator: this.props.layers.switchtype!=='colheight' ? this._shallowCompare : this._colCompare,
        opacity: this.props.gj2op,
        filled: true,
        autoHighlight: true,
        extruded: true,
        visible: this.props.layers.gj2data !== 'na',
        getElevation:  x=>this.calculateGjElevation(x,1),
        elevationScale: this.props.gjescale,
        parameters: this.props.layers.switchtype ==='opacity' ? { depthMask: false } : {}
      }),
      new GeoJsonLayer({
              id: 'gj3',
              data: this.props.layers.gj3data,
              getFillColor:  x=>this.calculateColor(x, 3),
              opacity: this.props.gj1op,
              filled: true,
              extruded: true,
              autoHighlight: true,
              pickable: this.props.gj1op !== 0,
              updateTriggers: {
                      getElevation: [this.props.heightSel],
                      getFillColor: [this.props.layers.gj1color, this.props.layers.gj2color, this.props.layers.gj3color]
                  },
              material,
              onHover: this._onHover,
              getElevation: x=>this.calculateGjElevation(x,2),
              visible: this.props.layers.gj3data !== 'na',
              elevationScale: this.props.gjescale,
              parameters: this.props.layers.switchtype ==='opacity'&this.props.layers.switchtype !=='hex' ? { depthMask: false } : {}
            }),
      new TripsLayer({
             id: 'trips',
             data: typeof this.props.layers.tripsdata == "string" ? [] : this.props.layers.tripsdata,
             getPath: d => d.d.map(p => [p[0], p[1], p[2]]),
             getColor: d => d.p !== null ? (PARTY_COLORS[d.p.toString()]) : [71, 61, 27],
             opacity: 1,
             billboard: true,
             widthMinPixels: 3,
             rounded: false,
             trailLength: 360,
             currentTime: this.props.curLayer
           })
    ];
  }

  render() {
    const {viewState, viewStateChange, onLoadz, baseMap = true} = this.props;
    const anims = this.heightanim.update({height1: this.props.layers.switchtype==='colheight' ? 1 : 0, height2: this.props.layers.switchtype==='colheight' ?  1 : 0 } )
    return (
      <DeckGL
        layers={this._renderLayers()}
        onViewStateChange={viewStateChange}
        effects={[lightingEffect]}
        viewState={viewState}
        controller={true}
      >
        {baseMap && (
          <StaticMap
            mapOptions={{customAttribution: "Hold Cntrl and drag to pan. | Paid for by Maricopa Remembers"}}
            reuseMaps
            onLoad={onLoadz}
            mapStyle="/data/style.json"
            preventStyleDiffing={true}
            mapboxApiAccessToken={MAPBOX_TOKEN}
          />
        )}
        {this._renderTooltip}
      </DeckGL>

    );
  }
}

export default DeckMap;

/*
    let mapDict = {
      'dtpall': [
      new HexagonLayer({
        id: 'voters',
        colorRange,
        coverage,
        data: ('/data/'+VOTER_URLS['alldropoff']),
        elevationRange: [0, 1000],
        elevationScale: this.state.elevationScale,
        extruded: true,
        getPosition: d => d,
        lightSettings: LIGHT_SETTINGS,
        onHover: this.props.onHover,
        opacity: 1,
        pickable: Boolean(this.props.onHover),
        radius,
        upperPercentile
      }),
      new GeoJsonLayer({
        id: 'polls',
        data: ('/data/'+POLL_URLS['16']),
        getFillColor: [165, 55, 253, 100],
        stroked: false,
        opacity: 1,
        filled: true,
        extruded: true,
        getElevation: 1500,
        elevationScale: this.state.elevationScale
      })
    ],    'polls': [
      new GeoJsonLayer({
        id: 'polls',
        data: ('/data/'+POLL_URLS['16']),
        getFillColor: [165, 55, 253, 100],
        stroked: false,
        opacity: 1,
        filled: true,
        extruded: true,
        getElevation: 1500,
        elevationScale: this.state.elevationScale
      }),
      new GeoJsonLayer({
        id: 'polls',
        data: ('/data/'+POLL_URLS['08']),
        getFillColor: [165, 55, 253, 100],
        stroked: false,
        opacity: 1,
        filled: true,
        extruded: true,
        getElevation: 1500,
        elevationScale: this.state.elevationScale
      })
    ]
    }
    */